const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';
const DEVICE_UUID = 'device-uuid';
const USER = 'user';
const LANGUAGE = 'language';
const AUTH_VERIFIED = 'f02901ec0bac6beaf2a3463af73e4a71b3c1bcd075c023c48fe8a88cafe6bb53';
const RESEND_CODE_TIMER = 'resend-code-timer';

export const getAccessToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN);
};

export const saveAccessToken = (token) => {
  window.localStorage.setItem(ACCESS_TOKEN, token);
};

export const destroyAccessToken = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN);
};

export const saveRefreshToken = (token) => {
  window.localStorage.setItem(REFRESH_TOKEN, token);
};

export const destroyRefreshToken = () => {
  window.localStorage.removeItem(REFRESH_TOKEN);
};

export const getDeviceUuid = () => {
  return window.localStorage.getItem(DEVICE_UUID);
};

export const saveDeviceUuid = (uuid) => {
  window.localStorage.setItem(DEVICE_UUID, uuid);
};

export const destroyDeviceUuid = () => {
  window.localStorage.removeItem(DEVICE_UUID);
};

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(USER));
};

export const saveUser = (user) => {
  window.localStorage.setItem(USER, JSON.stringify(user));
};

export const destroyUser = () => {
  window.localStorage.removeItem(USER);
};

export const getLanguage = () => {
  return window.localStorage.getItem(LANGUAGE);
};

export const setLanguage = (lang) => {
  window.localStorage.setItem(LANGUAGE, lang);
};

export const getAuthVerified = () => {
  return window.localStorage.getItem(AUTH_VERIFIED);
};

export const saveAuthVerified = () => {
  window.localStorage.setItem(AUTH_VERIFIED, '1');
};

export const destroyAuthVerified = () => {
  window.localStorage.removeItem(AUTH_VERIFIED);
};

export const getResendCodeTimer = () => {
  return window.localStorage.getItem(RESEND_CODE_TIMER);
};

export const saveResendCodeTimer = (value) => {
  window.localStorage.setItem(RESEND_CODE_TIMER, value);
};

export const destroyResendCodeTimer = () => {
  window.localStorage.removeItem(RESEND_CODE_TIMER);
};

export default {
  getAccessToken,
  saveAccessToken,
  destroyAccessToken,

  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,

  getDeviceUuid,
  saveDeviceUuid,
  destroyDeviceUuid,

  getUser,
  saveUser,
  destroyUser,
  
  getLanguage,
  setLanguage,

  getAuthVerified,
  saveAuthVerified,
  destroyAuthVerified,

  getResendCodeTimer,
  saveResendCodeTimer,
  destroyResendCodeTimer
};