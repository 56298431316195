import LocalStorageService from "@/core/services/local-storage.service";

export default {
  namespaced: true,
  state: {
    timeDetails: null,
    bookings: [],
    bookingChanges: null
  },
  
  getters: {
    TIME_DETAILS(state) {
      return state.timeDetails;
    },
    BOOKINGS(state) {
      return state.bookings;
    },
    BOOKING_CHANGES(state) {
      return state.bookingChanges;
    }
  },
  
  mutations: {
    SET_TIME_DETAILS(state, details) {
      state.timeDetails = details;
    },
    SET_BOOKINGS(state, bookings) {
      state.bookings = bookings;
    },
    SET_BOOKING_CHANGES(state, data) {
      state.bookingChanges = data;
    }
  },
  
  actions: {
    GET_TIME_DETAILS(context, {apartmentId, hash, timeHash}) {
      if (!apartmentId || !hash || !timeHash) return;
      
      return this.$api.get(`/api/qr/booking/${apartmentId}/${hash}/${timeHash}`)
        .then((response) => {
          context.commit('SET_TIME_DETAILS', response.data.data);
          
          return response.data.data;
        });
    },
    GET_BOOKINGS(context, {apartmentId, hash, timeHash}) {
      return this.$api.get(`/api/qr/booking/${apartmentId}/${hash}/${timeHash}/bookedDates`)
        .then(res => {
          context.commit('SET_BOOKINGS', res.data.data);
        });
    },
    GET_BOOKING_CHANGES(context, {bookingId, token}) {
      return this.$api.get(`/api/qr/book/change?booking_id=${bookingId}&token=${token}&lang=${LocalStorageService.getLanguage()}`)
        .then(res => {
          context.commit('SET_BOOKING_CHANGES', res.data.data);
        });
    },
    SEND_EMAIL(context, payload) {
      return this.$api.post(`api/qr/booking/${payload.apartmentId}/${payload.hash}`, {
        email: payload.email
      });
    },
    BOOK(context, {apartmentId, hash, timeHash, form}) {
      return this.$api.post(`api/qr/booking/${apartmentId}/${hash}/${timeHash}`, form);
    },
    ACCEPT_BOOKING_CHANGES(context, {token, booking_id}) {
      return this.$api.post(`/api/qr/book/confirm`, {
        token,
        booking_id,
        lang: LocalStorageService.getLanguage(),
        decision: 'accept'
      });
    },
    REJECT_BOOKING_CHANGES(context, {token, booking_id}) {
      return this.$api.post(`/api/qr/book/confirm`, {
        token,
        booking_id,
        lang: LocalStorageService.getLanguage(),
        decision: 'reject'
      });
    }
  }
};
