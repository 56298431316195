export default {
  namespaced: true,
  state: {
    revenue: null,
  },

  getters: {
    REVENUE(state) {
      return state.revenue;
    },
  },

  mutations: {
    SET_REVENUE(state, revenue) {
      state.revenue = revenue;
    },
  },

  actions: {
    GET_REVENUE(context, dataToSubmit) {
        return this.$api.get(`api/analytics/revenue`, {params: dataToSubmit}).then((response) => {
          context.commit('SET_REVENUE', response.data.data);
        });
    },
  }
};
