export default {
  btn: {
    accept: 'Принять',
    add_recommendation: 'Добавить рекомендацию',
    back: 'Назад',
    back_to_loyalty: 'Вернуться к бонусной системе',
    book: 'Забронировать',
    cancel: 'Отмена',
    cancel_booking: 'Отменить бронирование',
    change_password: 'Сменить пароль',
    clear: 'Очистить',
    click_to_download: 'Нажмите, чтобы скачать',
    confirm: 'Подтвердить',
    confirm_changes: 'Подтвердить изменения',
    continue_to_checkout: 'Продолжить оплату',
    copy_to_clipboard: 'Скопировать в буфер обмена',
    create: 'Создать',
    create_ticket: 'Создать Тикет',
    decline: 'Отклонить',
    delete: 'Удалить',
    download: 'Скачать',
    monthly_report: 'Ежемесячный отчет',
    daily_report: 'Ежедневный отчет',
    export_history: 'Экспорт истории',
    generate: 'Сгенерировать',
    generate_key: 'Сгенерировать ключ',
    get_back_home: 'Get back home',
    key_generation: 'Генерация ключей',
    next: 'Далее',
    login: 'Login',
    no: 'Нет',
    ok: 'Ok',
    order: 'Заказать',
    order_service: 'Заказать услугу',
    pause: 'Пауза',
    pay: 'Заплатить',
    resend_code: 'Отправить код еще раз',
    search: 'Поиск',
    send: 'Отправить',
    show_orders: 'Показать заказы',
    show_unpaid_invoices: 'Показать неоплаченные счета',
    sign_in: 'Войти',
    sign_out: 'Выход',
    sign_up: 'Зарегистрироваться',
    start: 'Старт',
    submit: 'Отправить',
    support: 'Написать в поддержку',
    reject: 'Отклонить',
    reset_password: 'Скинуть пароль',
    understand: 'Понятно',
    resume: 'Продолжить',
    update: 'Обновить',
    write_off: 'Списание',
    write_off_points: 'Заявление на списание баллов',
    yes: 'Да',
    change_package: 'Изменение пакета',
  },

  description: {
    already_qr_booked: 'Указанный адрес недействителен или уже был использован вами для бронирования посещения.',
    booking_changes_request_not_available: 'Запрос на изменение бронирования больше недоступен',
    booking_changes_request_not_available_desc: 'Этот запрос на изменение истек и больше не может быть доступен. Если вам все еще нужно изменить бронирование, пожалуйста, создайте новый запрос или свяжитесь с нашей службой поддержки.',
    coming_soon: 'Coming soon',
    check_email_for_GB_instructions: 'Проверьте ваш почтовый яшик.',
    enter_email_to_start_reservation: 'Для начало бронирования введите адрес электронной почты где вы получите дальнеюшую инструкцию.',
    enter_personal_details_for_gb: 'Введите свои личные данные, как они указаны в вашем паспорте.',
    list_is_empty: 'Список пуст',
    new_password_rules: 'Пароль должен состоять не менее чем из 8 символов и содержать хотя бы одну заглавную латинскую букву и одну цифру',
    no_apartments: 'У вас нет квартир',
    no_data: 'Нет данных',
    no_status: 'Нет статуса',
    notification_with_confirm_booking_changes_sent: 'Вам было отправлено письмо с подтверждением и обновленными данными бронирования.',
    notification_with_confirm_unchanged_sent: 'Вам было отправлено уведомление по электронной почте, подтверждающее, что ваше бронирование остается без изменений.',
    password_changed: 'Пароль успешно изменен',
    password_recovery_instructions_sent: 'Инструкции по восстановлению пароля были отправлены вам по электронной почте',
    please_wait: 'Пожалуйста подождите',
    reset_password_in_two_steps: 'Сбросьте пароль в два быстрых шага',
    review_proposed_booking_changes: 'Просмотрите и подтвердите предлагаемые изменения в вашем бронировании.',
    text_copied: 'Текст скопирован',
    guest_booking_time_is_ticking: '<b>Время идет!</b> У вас есть 10 минут, чтобы завершить бронирование. Если вы не завершите процесс в течение этого периода, ваш бронь будет анулирован.',
    guest_booking_time_is_up: 'С сожалением сообщаем, что 10-минутное окно для вашего бронирования истекло, и место больше не зарезервировано. Не волнуйся! Вы можете начать процесс бронирование заного.',
    someone_booked_dates_you_selected: 'Бронирование не удалось на заданный период. Пожалуйста, выберите альтернативные даты. На определенные даты мы рекомендуем бронировать как можно скорее, чтобы гарантировать ваше пребывание.',
    gb_request_created: 'Ваш запрос создан',
    gb_request_created_desc: 'Данные бронирования были отправлены на вашу электронную почту. Пожалуйста, проверьте свой почтовый ящик и приготовьтесь к фантастическому отдыху!',
    your_booking_unchanged: 'Ваше бронирование останется неизменным'
  },

  error: {
    children_age: 'Максимальный возраст ребенка 12 лет',
    children_count: 'Не соответствует количеству детей',
    field_is_required: 'Поле, обязательное для заполнения',
    invalid_age: 'Введён некорректный возраст',
    invalid_email: 'Неверный адрес электронной почты',
    invalid_number: 'Можно вводить только целые числа',
    min_length_is: 'Минимальная длина символов',
    password_security_failed: 'Пароль не соответствует минимальным требованиям безопасности',
    passwords_not_the_same: 'Пароли должны быть одинаковыми',
    reset_token_expired: 'Срок действия этого сеанса сброса пароля истек. Пожалуйста, нажмите «Сбросить пароль» и начните заново.',
    something_goes_wrong: 'Электронная почта недействительна. Попробуйте еще раз или свяжитесь с нами',
    wrong_credentials: 'Неверное имя пользователя или пароль',
  },

  help: {
    cant_delete_current_session: "Нельзя удалять текущую сессию",
    choose_apartment_and_income_date_first: 'Сначала выберите апартамент и дату прибытия',
    choose_apartment_first: 'Сначала выберите апартамент',
    ekey_desc_card: 'You are going to send electronic key to guest',
    ekey_desc_ekey: 'Вы собираетесь отправить цифровой ключ гостю, ' +
        'с помощью которого он может открыть дверь апартамента во время визита. ' +
        'Цифровой ключ будет отправлен на электронную почту вашего гостя.',
    enter_guest_fields: 'Заполните поля ниже, только если вы бронируете квартиру для кого-то еще',
    how_to_get_points: 'Как получить баллы',
    min_length_is: 'Минимальная длина символов:',
    redirect_to_payment_page: "Вы будете перенаправлены на страницу оплаты через 3 секунды",
    unconfirmed_points: 'Баллы должны быть подтверждены',
    who_will_get_keys: 'Кто получит ключи?',
    we_never_share_your_email: 'Мы никогда не поделимся вашей электронной почтой с кем-либо еще',
    we_never_share_your_phone_number: 'Мы никогда не передадим ваш номер телефона кому-либо еще',
    we_sent_2auth_code_to_email: 'Мы отправили код подтверждения на эмайл. Пожалуйста, введите его'
  },

  label: {
    accepted: 'Принято',
    active_keys: 'Активные ключи',
    address: 'Адрес',
    adult_age: 'Возраст от 13',
    adults: 'Взрослых',
    adults_plural: '{count} взрослый | {count} взрослые',
    age_needed: 'Требуется возраст',
    all: 'All',
    apartment: 'Апартамент',
    apartment_crm_id: 'Apartment CRM ID',
    apartments: 'Апартаменты',
    area: 'Area',
    attendees: 'Гости',
    authorization: 'Авторизация',
    available_points: 'Доступные баллы',
    balance: 'Баланс',
    block: 'Блок',
    booking: 'Бронирование',
    booking_accepted: 'Бронирование принято',
    booking_id: 'ID брони',
    booking_updated: 'Ваше бронирование успешно обновлено',
    bookings: 'Бронирования',
    bookings_count: 'Количество бронирований',
    bookings_new: 'Новый',
    bookings_current: 'Текущий',
    bought: 'Bought',
    cadastre: 'Кадастровый номер',
    calendar: 'Календарь',
    card: 'Картка',
    change_password: 'Сменить пароль',
    changes_confirmed: 'Изменения подтверждены',
    changes_declined: 'Изменения отклонены',
    check_in: 'Заезд',
    check_out: 'Отъезд',
    child_age: 'Возраст от 0 до 12 лет',
    children: 'Детей',
    children_age: 'Возраст детей ',
    children_plural: '{count} ребенок | {count} детей',
    citizenship: 'Гражданство',
    city: 'Город',
    click_to_toggle: 'Подробно',
    close_ticket: 'Закрыть Тикет',
    closed_ticket: 'Тикет закрыт',
    comment: 'Комментарий',
    confirm_booking: 'Подтвердить бронирование',
    confirm_password: 'Подтвердить Пароль',
    confirm_trusted_device: 'Подтвердить доверенное устройство',
    confirmed: 'Подтвержденный',
    confirmed_points: 'Подтвержденные баллы',
    contact: 'Контакт',
    copied: 'Скопировано',
    count: 'Количество',
    counter_id: 'Идентификатор счетчика',
    counter_readings: 'Показания счетчика',
    country: 'Страна',
    current_booking: 'Текущее бронирование',
    current_booking_details: 'Текущие данные бронирования',
    current_password: 'Текущий пароль',
    date: 'Дата',
    debt: 'Долг',
    departure: 'Departure',
    desktop: 'Рабочий стол',
    device: 'Устройство',
    dont_generate: 'Не генерировать',
    dont_have_account: 'У вас еще нет учетной записи',
    door_openings: 'Doors openings',
    doors_history: 'История дверей',
    download_data: 'Скачать данные',
    ekey: 'Цифровой ключ',
    electricity: 'Электричество',
    first_login: 'Первый вход',
    internet_tv: 'Интернет и ТВ',
    email: 'Эл. адрес',
    email_address: 'Электронный адрес',
    entry: 'Entry',
    exit_date: 'Дата отбытия',
    female: 'Женский',
    floor: 'Этаж',
    forgot_password: 'Забыли пароль',
    full_name: 'ФИО',
    gender: 'Пол',
    generate_key: 'Хотите сгенерировать и отправить ключ?',
    guests: 'Гости',
    have_account: 'Уже есть аккаунт',
    hello: 'Привет',
    history: 'История',
    id: 'ID',
    in_process: 'В процессе',
    income_date: 'Дата прибытия',
    invoice: 'Счет',
    guest: 'Гость',
    last_login: 'Последний вход',
    last_name: 'Фамилия',
    last_opened_at: 'Последний раз открывался в',
    last_update: 'Последнее обновление',
    leave_something_for_host: 'Можете дополнительно указать ',
    link_expired: 'Срок действия этой ссылки истек',
    log: 'Log',
    male: 'Мужской',
    manual: 'Вручную',
    me: 'Me',
    messenger: 'Мессенджер',
    month: 'Месяц',
    my_recommendations: 'Мои рекомендации',
    name: 'Имя',
    next_payment: 'Следующий платеж',
    new_password: 'Новый пароль',
    nights_count: 'Количество ночей',
    number: 'Номер',
    object: 'Объект',
    online: 'Онлайн',
    oops: 'Внимание',
    orbi_group: 'ОРБИ Груп',
    orders: 'Заказы',
    orders_total: 'Всего заказов',
    os_system: 'OS система',
    owner: 'Владелец',
    book_for_myself: 'Бронировать для себя',
    book_for_guest: 'Бронировать для гостя',
    password: 'Пароль',
    password_recovery: 'Восстановление пароля',
    passport_number: 'Номер паспорта',
    paused_keys: 'Приостановленные ключи',
    payment_type: 'Способ оплаты',
    person: 'Персон',
    personal_id: 'Персональный ID',
    personal_number: 'Номер паспорта',
    phone_number: 'Телефонный номер',
    point: 'Point',
    point_s: 'Point(s)',
    points: 'баллов',
    price: 'Цена',
    proposed_changes: 'Proposed changes',
    sum: 'Сумма',
    products: 'Товары',
    profile: 'Профиль',
    refused: 'Отказался',
    recommendations: 'Рекомендации',
    recommendations_statistics: 'Статистика рекомендаций',
    remains_to_pay: 'Осталось оплатить',
    revenue: 'Доход',
    second_name: 'Второе имя',
    services: 'Услуги',
    apartment_service: 'Обслуживание',
    security_policy: 'Политика безопасности',
    sign_in: 'Войти',
    sign_up: 'Регистрация',
    smartphone: 'Смартфон',
    spent_points: 'Потраченные баллы',
    square: 'Квадрат',
    status: 'Статус',
    table: 'Таблица',
    terms_and_conditions: 'Условия и положения',
    today_readings: 'сегодняшние чтения',
    total: 'Всего',
    type: 'Источник',
    unconfirmed_points: 'Неподтвержденные баллы',
    until_full_repayment: 'До полного погашения',
    updated_booking_details: 'Обновленные данные бронирования',
    utilities: 'Коммунальные услуги',
    username: 'Имя пользователя',
    vat: 'НДС',
    visa: 'Visa',
    visa_mastercard: 'Visa / Mastercard',
    water: 'Вода',
    year: 'Год',
    years_old: 'год',
    you_earned_point: 'Вы заработали балл',
    you_have: 'У тебя есть',
    your_personal_number: 'Ваш личный номер',
    your_wishes: 'Ваши пожелания',
    open: 'Открытый',
    closed: 'Закрыто',
    passport_scan: 'Скан паспорта',
    ep_code: 'epCode',
    monthly_tariff: 'Ежемесячный тариф',
    day: 'День',
    start_date: 'Дата начала обслуживания',
    water_code: 'Код воды',
    active: 'Активный',
    passive: 'Пассивный',
    paused: 'Приостановленный',
    cost: 'Расходы',
    discount: 'Скидка',
    disabled_date: 'Дата отключения',
    internet: 'ИНТЕРНЕТ',
    tv: 'ТЕЛЕВИДЕНИЕ',
    choose_language: 'Choose language',
    when_is_trip: 'Период бронирования',
    who_is_coming: 'Информация о гостях',
    who_is_your_guest: 'Информация о гостях'
  },

  lang: {
    en: "English",
    ge: "ქართული",
    ru: "Русский"
  },

  link: {},

  modal: {
    accept_booking_question: 'Вы действительно хотите принять бронирование?',
    accept_booking_parallel_bookings: 'Подтверждение также приведет к отказу от других посещений:',
    accept_booking_with_key_title: 'Вы принимаете бронирование и собираетесь создать цифровой ключ для вашего гостя, ' +
        'с помощью которого он может открыть дверь апартамента во время визита.',
    accept_booking_with_key_question: 'Вы действительно хотите принять бронирование и сгенерировать цифровой ключ от апартамента?',
    accept_booking_without_key_title: 'Вы принимаете бронирование, не генерируя цифровой ключ от апартамента.',
    are_you_sure: 'Are you sure',
    booking_accepted: 'Ваш гость получил все детали бронирования и цифровой ключ на электронную почту',
    booking_accepted_body: 'Бронирование успешно принято',
    booking_created_body: 'Бронирование успешно создано',
    booking_created_title: 'Бронирование создано',
    booking_created_for_guest_desc_1: 'Ваш гость получил электронное письмо с информацией о бронировании',
    booking_created_for_guest_desc_2: 'Примите это бронирование в течение 48 часов, иначе оно будет автоматически отклонено.',
    booking_created_for_guest_desc_3: 'Вы можете передать цифровой ключ гостю при принятии бронирования.',
    booking_declined: 'Бронирование отклонено',
    booking_declined_body: 'Бронирование успешно отклонено',
    confirm_device_delete: 'Внимание! Вы хотите удалить  устройства? Вы можете снова добавить устройство. Если вы сначала пройдете аутентификацию с этого устройства и введете код по SMS.',
    confirm_internet_pause: 'Внимание! Вы хотите приостановить предоставление услуги? Ежемесячная плата за паузу составляет 6 лари',
    confirm_internet_resume: 'Внимание! Вы уверены что хотите продолжить?',
    confirm_package_order: 'Вы действительно хотите сменить пакет?',
    decline_booking_question: 'Вы действительно хотите отказаться от бронирования?',
    key_generated: 'Цифровой ключ сгенерирован',
    key_generated_body: 'Цифровой ключ успешно сгенерирован',
    generate_key_for_guest_title: 'Вы собираетесь отправить цифровой ключ гостю, ' +
        'с помощью которого он может открыть дверь апартамента во время визита.',
    generate_key_for_guest_body: 'Цифровой ключ будет отправлен на электронную почту вашего гостя.',
    generate_key_for_guest_question: 'Хотите сгенерировать и отправить ключ?',
    you_can_generate_key: 'Вы также можете создать цифровой ключ для вашего гостя, ' +
        'с помощью которого он может открыть дверь апартамента во время визита.',
    generate_key_for_personal_title: 'Вы получите цифровой ключ по электронной почте, ' +
        'Что позволит открыть дверь апартамента во время визита.',
    generate_key_for_personal_body: 'Цифровой ключ будет отправлен на вашу электронную почту.',
  },

  month: {
    all: 'Все',
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
  },

  navigation: {
    analytics: 'Аналитика',
    apartment_details: 'Детали апартамента',
    apartments: 'Апартаменты',
    bonus_program: 'Бонусная программа',
    booking_changes_confirmed: 'Изменения бронирования подтверждены',
    booking_changes_declined: 'Изменения в бронировании отклонены',
    booking_changes_link_expired: 'Ссылка на изменение бронирования истекла',
    bookings_and_visits: 'Бронирование и посещения',
    change_booking: 'Изменить бронирование',
    control_panel: 'Панель управления',
    dashboard: 'Дашборд',
    deals: 'Предложения',
    devices: 'Устройства',
    your_devices: 'Ваши устройства',
    documents: 'Документы',
    finances: 'Финансы',
    forgot_password: 'Забыли пароль',
    help: 'Центр помощи',
    loyalty: 'Бонусная программа',
    init_guest_booking: 'Start your reservation',
    my_apartments: 'Мои апартаменты',
    my_documents: 'Мои документы',
    new_recommendation: 'Новая рекомендация',
    order: 'Order',
    orders: 'Заказы',
    pay: 'Заплатить',
    payments: 'Платежи',
    profile: 'Профиль',
    qr_book_cancel: 'Детали бронирования',
    request_guest_booking: 'Book your apartment',
    reset_password: 'Сброс пароля',
    services: 'Услуги',
    sign_in: 'Войти',
    sign_up: 'Зарегистрироваться',
    support: 'Поддержка',
    terms: 'Условия и положения',
    transactions: 'Транзакции',
    verification: 'Двухфакторная авторизация',
    invoices: 'Счета-фактуры',
    financial_turnover_to_the_contract_of_sale: 'Финансовый оборот к договору купли продажи',
    bank_transactions: 'Банковские операции',
    contract_payment_schedule: 'График оплат по договору',
    billing_utilities_services: 'Биллинг, ком. услуги',
    financial_turnover_of_your_own_apartment: 'Финансовый оборот собственного апартамента',
  },

  notification: {
    new_booking: 'У вас есть неподтвержденная бронь',
    new_bookings: 'У вас есть неподтвержденные бронирования',
    notifications: 'Уведомления',
    no_new_notifications: 'У вас нет уведомлений',
    unread_messages: 'У вас есть непрочитанные сообщения',
  },

  notify: {
    incorrect_token: 'Неверный токен',
    request_already_handled: 'Запрос уже был обработан',
    you_canceled_account_activation: 'Вы отменили активацию учетной записи',
    your_account_activated: 'Ваша учетная запись активирована. Проверьте свою электронную почту',
  },

  pagination: {},

  placeholder: {
    check_in_date: 'Дата заезда',
    check_out_date: 'Дата отъезда',
    choose_apartment: 'Выбрать апартамент',
    choose_citizenship: 'Выберите гражданство',
    choose_country: 'Выберите страну',
    choose_date_and_time: 'Выберите дату и время',
    choose_department: 'Выберите отдел',
    choose_guest_citizenship: 'Выберите гражданство гостя',
    choose_guest_country: 'Выберите страну гостя',
    choose_guest_gender: 'Выберите пол гостя',
    choose_income_date: 'Выберите дату прибытия',
    choose_exit_date: 'Выберите дату отбытия',
    choose_language: 'Выберите язык',
    choose_payment_type: 'Выберите тип оплаты',
    choose_who_will_get_keys: 'Выберите, кто получит ключи',
    from: 'От',
    enter_child_age: 'Enter child age',
    enter_children_age: 'Введите возраст детей',
    enter_city: 'Введите город',
    enter_comment: 'Введите комментарий',
    enter_country: 'Введите страну',
    enter_current_password: 'Введите текущий пароль',
    enter_email: 'Введите Эл. адрес',
    enter_guest_address: 'Введите адрес гостя',
    enter_guest_email: 'Введите email гостя',
    enter_guest_name: 'Введите имя гостя',
    enter_guest_last_name: 'Введите фамилию гостя',
    enter_guest_personal_id: 'Введите номер паспорта гостя',
    enter_guest_phone_number: 'Введите номер телефона гостя',
    enter_last_name: 'Введите фамилию',
    enter_messenger: 'Введите Мессенджер',
    enter_name: 'Введите имя',
    enter_new_password: 'Введите новый пароль',
    enter_new_password_one_more_time: 'Введите новый пароль еще раз',
    enter_password: 'Введите пароль',
    enter_personal_id: 'Введите персональный ID',
    enter_phone_number: 'Введите номер телефона',
    enter_second_name: 'Введите второе имя',
    enter_title: 'Введите название',
    enter_username: 'Введите имя пользователя',
    to: 'К',
    repeat_new_password: 'Повторите новый пароль',
    type_message: 'Введите сообщение',
    ticket: 'Тикет',
    upload_file: 'Загрузить файл',
    choose_type: 'Выберите тип',
  },

  table: {
    per_page: 'Количество записей',
    empty: 'Нет записей для показа'
  },

  title: {
    bonus_program: 'Бонусная программа',
    booking_form: 'Форма бронирования',
    book_for_myself: 'Забронировать для себя',
    booking_confirmation: 'Подтверждение бронирования',
    booking_details: 'Детали бронирования',
    booking_details_on_the_way: 'На ваш адрес электронной почты было отправлены Инструкция по завершению бронирования.',
    bookings_and_visits: 'Бронирование и посещения',
    confirmation: 'Подтверждение',
    contracts: 'Контракты',
    create_new_ticket: 'Создать новый Тикет',
    data_view: 'Просмотр данных',
    electronic_keys: 'Цифровые ключи',
    error: 'Ошибка',
    every_stay_story: 'Где каждое пребывание — это история',
    forgot_password: 'Забыли пароль',
    my_recommendations: 'Мои рекомендации',
    payment_details: 'Детали оплаты',
    services: 'Услуги',
    apartment_service: 'Обслуживание',
    sign_in: 'Войти',
    sign_up: 'Зарегистрироваться',
    success: 'Success',
    user_profile: 'Профиль пользователя',
    utilities: 'Коммунальные услуги',
    filters: 'Фильтры',
  },

  status: {
    booking: {
      approved: 'Одобренный',
      canceled: 'Отмененный',
      new: 'Новый',
    },
    points: {
      all: 'Все',
      in_process: 'В процессе',
      new: 'Новый',
      purchase: 'Bought',
      refuse: 'Отказался',
    }
  },

  ticketTypes: {
    financial_issues: 'Финансовый вопрос',
    technical_problem: 'Техническая нейсправность',
    booking_questions: 'Вопросы по бронированю',
    digital_key_issue: 'Вопросы по поводу цифрового ключа',
    internet_tv_phone: 'Интернет и ТВ',
    other: 'Другие вопросы',
  }

};
