export default {
  namespaced: true,
  state: {
    waterTotal: null
  },
  getters: {
    WATER_TOTAL(state) {
      return state.waterTotal;
    },
  },
  mutations: {
    CLEAR_DATA(state) {
      state.waterTotal = null;
    }
  },
  actions: {
    GET_FILE(context, {apartmentID, filters, type}) {
      let routeName;

      switch (type) {
        case 'pdf':
          routeName = 'filePdf';
          break;
        default:
          routeName = 'file';
          break;
      }

      return this.$api.get(`api/apartments/water/${apartmentID}/${routeName}`, {params: filters})
        .then((response) => {
          window.open(response.data);
        });
    },
    GET_WATER_TOTAL(context) {
      if (!context.state.waterTotal) {
        return this.$api.get('api/apartments/water/total', null, false).then((response) => {
          context.state.waterTotal = response.data.data;
        });
      }
    },
  }
};
