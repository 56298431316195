export default {
  btn: {
    accept: 'Accept',
    add_recommendation: 'Add recommendation',
    back: 'Back',
    back_to_loyalty: 'Back to loyalty',
    book: 'Book',
    cancel: 'Cancel',
    cancel_booking: 'Cancel Booking',
    change_password: 'Change password',
    clear: 'Clear',
    click_to_download: 'Click To Download',
    confirm: 'Confirm',
    confirm_changes: 'Confirm changes',
    continue_to_checkout: 'Continue to checkout',
    copy_to_clipboard: 'Copy to clipboard',
    create: 'Create',
    create_ticket: 'Create ticket',
    decline: 'Decline',
    delete: 'Delete',
    download: 'Download',
    monthly_report: 'Monthly report',
    daily_report: 'Daily report',
    export_history: 'Export history',
    generate: 'Generate',
    generate_key: 'Generate key',
    get_back_home: 'Get back home',
    key_generation: 'Key generation',
    next: 'Next',
    no: 'No',
    ok: 'Ok',
    order: 'Order',
    order_service: 'Order service',
    pause: 'Pause',
    pay: 'Pay',
    resend_code: 'Resend code',
    search: 'Search',
    send: 'Send',
    show_orders: 'Show orders',
    show_unpaid_invoices: 'Show unpaid invoices',
    sign_in: 'Sign in',
    sign_out: 'Sign out',
    sign_up: 'Sign up',
    start: 'Start',
    submit: 'Submit',
    support: 'Write to support',
    reject: 'Reject',
    reset_password: 'Reset password',
    understand: 'Understand',
    resume: 'Resume',
    update: 'Update',
    write_off: 'Write off',
    write_off_points: 'Points write off application',
    yes: 'Yes',
    change_package: 'Change package',
  },

  description: {
    already_qr_booked: 'The mentioned address is invalid or has already been used - to book a visit - by you.',
    booking_changes_request_not_available: 'The booking changes request is no longer available',
    booking_changes_request_not_available_desc: 'This change request has expired and can no longer be accessed. If you still need to modify your booking, please create a new request or contact our support team.',
    coming_soon: 'Coming Soon',
    check_email_for_GB_instructions: 'Check your email for instructions on completing your booking.',
    enter_email_to_start_reservation: 'Enter your email below to start your reservation. You\'ll receive a link to finalize booking details. Your seamless stay begins with a click!',
    enter_personal_details_for_gb: 'Enter your personal details as they appear on your passport. This ensures a smooth process',
    list_is_empty: 'List is empty',
    new_password_rules: 'The password must consist of at least 8 characters and contain at least one uppercase Latin letter and one number',
    no_apartments: 'You don\'t have any apartments',
    no_data: 'Nothing to show here',
    no_status: 'No status',
    notification_with_confirm_booking_changes_sent: 'A confirmation email has been sent with your updated booking details.',
    notification_with_confirm_unchanged_sent: 'A notification email has been sent confirming that your booking remains unchanged.',
    password_changed: 'Password changed successfully',
    password_recovery_instructions_sent: 'Password recovery instructions have been sent to you by e-mail',
    please_wait: 'Please wait',
    reset_password_in_two_steps: 'Reset your password in two quick steps',
    review_proposed_booking_changes: 'Review and confirm the proposed changes to your booking',
    text_copied: 'Text copied',
    guest_booking_time_is_ticking: '<b>Time is ticking!</b> You\'ve got a 10-minute window to secure your booking spot. If you don\'t complete the process within this timeframe, your reserved spot will be released.',
    guest_booking_time_is_up: 'We\'re sorry to say that the 10-minute window for your booking has ended, and the spot is no longer reserved. No stress! You can simply request another reservation email, and we\'ll guide you through the process',
    someone_booked_dates_you_selected: 'It looks like someone booked the dates you selected. Please choose alternative dates. For specific dates, we recommend booking soon to secure your stay. Thanks for understanding, and we can\'t wait to welcome you!',
    gb_request_created: 'Your request has been created',
    gb_request_created_desc: 'Your reservation details have been sent to your email. Please check your inbox and get ready for a fantastic stay!',
    your_booking_unchanged: 'Your booking will remain unchanged'
  },

  error: {
    children_age: 'Max child age is 12 years old',
    children_count: 'Does not match the number of children',
    field_is_required: 'Field is required',
    invalid_age: 'Invalid age entered',
    invalid_email: 'Invalid E-mail',
    invalid_number: 'Invalid number',
    min_length_is: 'Min symbols length is',
    password_security_failed: 'Password does not meet minimum security requirements',
    passwords_not_the_same: 'Passwords need to be the same',
    reset_token_expired: 'This password reset session has expired. Please click "Reset Password" and start over.',
    something_goes_wrong: 'Email is invalid. Try again or contact us',
    wrong_credentials: 'Username or password are incorrect',
  },

  help: {
    cant_delete_current_session: "You can't delete current session",
    choose_apartment_and_income_date_first: 'Choose an apartment and arrival date first',
    choose_apartment_first: 'Choose an apartment first',
    ekey_desc_card: 'You are going to send digital key to guest',
    ekey_desc_ekey: 'You are going to send an digital key to a guest, ' +
        'with which he can open the door of the apartment during the visit. ' +
        'An digital key will be sent to your guest’s email.',
    enter_guest_fields: 'Fill below fields only if you book apartment for someone else',
    how_to_get_points: 'How to get points',
    min_length_is: 'Min length of symbols:',
    redirect_to_payment_page: "You will be redirected to payment page after 3 seconds",
    unconfirmed_points: 'Point(s) needs to be confirmed',
    who_will_get_keys: 'Who will get keys?',
    we_never_share_your_email: 'We\'ll never share your email with anyone else',
    we_never_share_your_phone_number: 'We\'ll never share your phone number with anyone else',
    we_sent_2auth_code_to_email: 'We have sent a confirmation code to your email. Please enter it'
  },

  label: {
    accepted: 'Accepted',
    active_keys: 'Active keys',
    address: 'Address',
    adult_age: 'Ages 13 or above',
    adults_plural: '{count} adult | {count} adults',
    adults: 'Adults',
    age_needed: 'Age needed',
    all: 'All',
    apartment: 'Apartment',
    apartment_crm_id: 'Apartment CRM ID',
    apartments: 'Apartments',
    area: 'Area',
    attendees: 'Attendees',
    authorization: 'Authorization',
    available_points: 'Available point(s)',
    balance: 'Balance',
    block: 'Block',
    booking: 'Booking',
    booking_accepted: 'Booking accepted',
    booking_id: 'Booking ID',
    booking_updated: 'Your booking has been successfully updated',
    bookings: 'Bookings',
    bookings_count: 'Bookings count',
    bookings_new: 'New',
    bookings_current: 'Current',
    bought: 'Bought',
    cadastre: 'Cadastral number',
    calendar: 'Calendar',
    card: 'Card',
    change_password: 'Change password',
    changes_confirmed: 'Changes confirmed',
    changes_declined: 'Changes declined',
    check_in: 'Check in',
    check_out: 'Check out',
    child_age: 'Ages 0 to 12',
    children: 'Children',
    children_age: 'Children age',
    children_plural: '{count} child | {count} children',
    citizenship: 'Citizenship',
    city: 'City',
    click_to_toggle: 'Click to toggle',
    close_ticket: 'Close ticket',
    closed_ticket: 'Ticket Closed',
    comment: 'Comment',
    confirm_booking: 'Confirm booking',
    confirm_password: 'Confirm password',
    confirm_trusted_device: 'Confirm trusted device',
    confirmed: 'confirmed',
    confirmed_points: 'Confirmed points',
    contact: 'Contact',
    copied: 'Copied',
    count: 'Count',
    counter_id: 'Counter ID',
    counter_readings: 'Counter readings',
    country: 'Country',
    current_booking: 'Current booking',
    current_booking_details: 'Current booking details',
    current_password: 'Current password',
    date: 'Date',
    debt: 'Debt',
    departure: 'Departure',
    desktop: 'Desktop',
    device: 'Device',
    dont_generate: 'Don\'t generate',
    dont_have_account: 'Don\'t have an account yet',
    door_openings: 'Doors openings',
    doors_history: 'Doors history',
    download_data: 'Download data',
    ekey: 'Digital key',
    electricity: 'Electricity',
    first_login: 'First login',
    internet_tv: 'Internet and TV',
    email: 'Email',
    email_address: 'Email address',
    entry: 'Entry',
    exit_date: 'Departure date',
    female: 'Female',
    floor: 'Floor',
    forgot_password: 'Forgot password',
    full_name: 'Full name',
    gender: 'Gender',
    generate_key: 'Do you want to generate & send key?',
    guests: 'Guests',
    have_account: 'Have an account',
    hello: 'Hello',
    history: 'History',
    id: 'ID',
    in_process: 'In process',
    income_date: 'Arrival date',
    invoice: 'Invoice',
    guest: 'Guest',
    last_login: 'Last login',
    last_name: 'Last name',
    last_opened_at: 'Last opened at',
    last_update: 'Last update',
    leave_something_for_host: 'Leave something for host',
    link_expired: 'This link has expired',
    log: 'Log',
    male: 'Male',
    manual: 'Manual',
    me: 'Me',
    messenger: 'Messenger',
    month: 'Month',
    my_recommendations: 'My recommendations',
    name: 'Name',
    next_payment: 'Next payment',
    new_password: 'New password',
    nights_count: 'Nights count',
    number: 'Number',
    object: 'Object',
    online: 'Online',
    oops: 'Attention',
    orbi_group: 'ORBI Group',
    orders: 'Orders',
    orders_total: 'Orders total',
    os_system: 'OS system',
    owner: 'Owner',
    book_for_myself: 'Book for myself',
    book_for_guest: 'Book for guest',
    password: 'Password',
    password_recovery: 'Password recovery',
    passport_number: 'Passport number',
    paused_keys: 'Paused keys',
    payment_type: 'Payment type',
    person: 'Person',
    personal_id: 'Personal ID',
    personal_number: 'Passport number',
    phone_number: 'Phone number',
    point: 'Point',
    point_s: 'Point(s)',
    points: 'Points',
    price: 'Price',
    proposed_changes: 'Proposed changes',
    sum: 'Sum',
    products: 'Products',
    profile: 'Profile',
    refused: 'Refused',
    recommendations: 'Recommendations',
    recommendations_statistics: 'Recommendations statistics',
    remains_to_pay: 'Remains to pay',
    revenue: 'Revenue',
    second_name: 'Second name',
    services: 'Services',
    apartment_service: 'Maintenance',
    security_policy: 'Security Policy',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
    smartphone: 'Smartphone',
    spent_points: 'Spent points',
    square: 'Square',
    status: 'Status',
    table: 'Table',
    terms_and_conditions: 'Terms and Conditions',
    today_readings: 'Today readings',
    total: 'Total',
    type: 'Source',
    unconfirmed_points: 'Unconfirmed points',
    until_full_repayment: 'Until full repayment',
    updated_booking_details: 'Updated booking details',
    utilities: 'Utilities',
    username: 'Username',
    vat: 'Vat',
    visa: 'Visa',
    visa_mastercard: 'Visa / Mastercard',
    water: 'Water',
    year: 'Year',
    years_old: 'Years old',
    you_earned_point: 'You earned point',
    you_have: 'You have',
    your_personal_number: 'Your personal number',
    your_wishes: 'Your wishes',
    open: 'Open',
    closed: 'Closed',
    passport_scan: 'Passport scan',
    ep_code: 'epCode',
    monthly_tariff: 'Monthly Tariff',
    day: 'Day',
    start_date: 'Service Start Date',
    water_code: 'Water Code',
    active: 'Active',
    passive: 'Passive',
    paused: 'Paused',
    cost: 'Cost',
    discount: 'Discount',
    disabled_date: 'Disabled Date',
    internet: 'INTERNET',
    tv: 'TV',
    choose_language: 'Choose language',
    when_is_trip: 'When is your trip',
    who_is_coming: 'Who is coming',
    who_is_your_guest: 'Who is our Guest'
  },

  lang: {
    en: "English",
    ge: "ქართული",
    ru: "Русский"
  },

  link: {},

  modal: {
    accept_booking_question: 'Do you really want to accept booking?',
    accept_booking_parallel_bookings: 'Confirmation will also result in refusal of other premises:',
    accept_booking_with_key_title: 'You are now accepting the booking and intending to send an digital key to your guest, ' +
      'with which he can open the door of the apartment during the visit.',
    accept_booking_with_key_question: 'Do you really want to accept booking and generate the digital apartment key?',
    accept_booking_without_key_title: 'You are now accepting the booking without generating an digital apartment key',
    are_you_sure: 'Are you sure',
    booking_accepted: 'Your guest has received all reservation details and an digital key on e-mail',
    booking_accepted_body: 'Booking has been accepted successfully',
    booking_created_body: 'Booking has been successfully created',
    booking_created_title: 'Booking created',
    booking_created_for_guest_desc_1: 'Your guest got an email with booking information',
    booking_created_for_guest_desc_2: 'Please accept this booking in 48 hours or it will be declined automatically',
    booking_created_for_guest_desc_3: 'You can give an digital key for your guest while accepting booking',
    booking_declined: 'Booking declined successfully',
    booking_declined_body: 'Booking has been declined successfully',
    confirm_device_delete: 'Attention! Do you want to remove device? You can add the device again. You need to authenticate from this device and enter the code via SMS.',
    confirm_internet_pause: 'Attention! Do you want to pause the service? Monthly pause fee is 6 GEL',
    confirm_internet_resume: 'Attention! Are you sure you want to resume?',
    confirm_package_order: 'Do you really want to change package?',
    decline_booking_question: 'Do you really want to decline booking?',
    key_generated: 'Digital key generated successfully',
    key_generated_body: 'Digital key has been generated successfully',
    generate_key_for_guest_title: 'You are going to send an digital key to a guest, ' +
        'with which he can open the door of the apartment during the visit.',
    generate_key_for_guest_body: 'An digital key will be sent to your guest’s email.',
    generate_key_for_guest_question: 'Do you want to generate and send the key?',
    you_can_generate_key: 'You can also create an digital key for your guest, ' +
      'with which he can open the door of the apartment during the visit.',
    generate_key_for_personal_title: 'You will receive an digital key via email, ' +
        'which will allow you to open the door of the apartment during the visit.',
    generate_key_for_personal_body: 'An digital key will be sent to your email.',
  },

  month: {
    all: 'All',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },

  navigation: {
    analytics: 'Analytics',
    apartment_details: 'Apartment Details',
    apartments: 'Apartments',
    bonus_program: 'Bonus Program',
    booking_changes_confirmed: 'Booking Changes Confirmed',
    booking_changes_declined: 'Booking Changes Declined',
    booking_changes_link_expired: 'Booking Changes Link Expired',
    bookings_and_visits: 'Bookings and Visits',
    change_booking: 'Change Booking',
    control_panel: 'Control Panel',
    dashboard: 'Dashboard',
    deals: 'Deals',
    devices: 'Devices',
    your_devices: 'Your Devices',
    documents: 'Documents',
    finances: 'Finances',
    forgot_password: 'Forgot password',
    help: 'Help Center',
    init_guest_booking: 'Start your reservation',
    loyalty: 'Loyalty',
    my_apartments: 'My Apartments',
    my_documents: 'My Documents',
    new_recommendation: 'New Recommendation',
    order: 'Order',
    orders: 'Orders',
    pay: 'Pay',
    payments: 'Payments',
    profile: 'Profile',
    qr_book_cancel: 'Booking Details',
    request_guest_booking: 'Book your apartment',
    reset_password: 'Reset Password',
    services: 'Services',
    sign_in: 'Sign In',
    sign_up: 'Sign Up',
    support: 'Support',
    terms: 'Terms and Conditions',
    transactions: 'Transactions',
    verification: 'Two-factor authentication',
    invoices: 'Invoices',
    financial_turnover_to_the_contract_of_sale: 'Financial turnover to the contract of sale',
    bank_transactions: 'Bank Transactions',
    contract_payment_schedule: 'Contract Payment Schedule',
    billing_utilities_services: 'Billing, utilities, service',
    financial_turnover_of_your_own_apartment: 'Financial turnover of own your apartment',
  },

  notification: {
    new_booking: 'You have unconfirmed booking',
    new_bookings: 'You have unconfirmed bookings',
    notifications: 'Notifications',
    no_new_notifications: 'You don\'t have any notification',
    unread_messages: 'You have unread messages',
  },

  notify: {
    incorrect_token: 'Incorrect token',
    request_already_handled: 'Request was already handled',
    you_canceled_account_activation: 'You canceled account activation',
    your_account_activated: 'Your account has been activated. Check your email',
  },

  pagination: {},

  placeholder: {
    check_in_date: 'Check-in date',
    check_out_date: 'Check-out date',
    choose_apartment: 'Choose apartment',
    choose_citizenship: 'Choose citizenship',
    choose_country: 'Choose country',
    choose_date_and_time: 'Choose date & time',
    choose_department: 'Choose department',
    choose_guest_citizenship: 'Choose guest citizenship',
    choose_guest_country: 'Choose guest country',
    choose_guest_gender: 'Choose guest gender',
    choose_income_date: 'Choose arrival date',
    choose_exit_date: 'Choose departure date',
    choose_language: 'Choose language',
    choose_payment_type: 'Choose payment type',
    choose_who_will_get_keys: 'Choose who will get keys',
    from: 'From',
    enter_child_age: 'Enter child age',
    enter_children_age: 'Enter children age',
    enter_city: 'Enter city',
    enter_comment: 'Enter comment',
    enter_country: 'Enter country',
    enter_current_password: 'Enter current password',
    enter_email: 'Enter email',
    enter_guest_address: 'Enter guest address',
    enter_guest_email: 'Enter guest email',
    enter_guest_name: 'Enter guest name',
    enter_guest_last_name: 'Enter guest last name',
    enter_guest_personal_id: 'Enter guest passport number',
    enter_guest_phone_number: 'Enter guest phone number',
    enter_last_name: 'Enter last name',
    enter_messenger: 'Enter messenger',
    enter_name: 'Enter name',
    enter_new_password: 'Enter new password',
    enter_new_password_one_more_time: 'Enter new password one more time',
    enter_password: 'Enter password',
    enter_personal_id: 'Enter personal ID',
    enter_phone_number: 'Enter phone number',
    enter_second_name: 'Enter second name',
    enter_title: 'Enter title',
    enter_username: 'Enter username',
    repeat_new_password: 'Repeat new password',
    to: 'To',
    type_message: 'Type a message',
    ticket: 'Ticket',
    upload_file: 'Upload file',
    choose_type: 'Choose type',
  },

  table: {
    per_page: 'Per page',
    empty: 'There are no records to show'
  },

  title: {
    bonus_program: 'Bonus Program',
    booking_form: 'Booking form',
    book_for_myself: 'Book for myself',
    booking_confirmation: 'Bookings Confirmation',
    booking_details: 'Booking Details',
    booking_details_on_the_way: 'Your booking details are on their way!',
    bookings_and_visits: 'Bookings and Visits',
    confirmation: 'Confirmation',
    contracts: 'Contracts',
    create_new_ticket: 'Create New Ticket',
    data_view: 'Data view',
    electronic_keys: 'Digital Keys',
    error: 'Error',
    every_stay_story: 'Where Every Stay is a Story',
    forgot_password: 'Forgot Password',
    my_recommendations: 'My Recommendations',
    payment_details: 'Payment details',
    services: 'Services',
    apartment_service: 'Maintenance',
    sign_in: 'Sign In',
    sign_up: 'Sign Up',
    success: 'Success',
    user_profile: 'User Profile',
    utilities: 'Utilities',
    filters: 'Filters',
  },

  status: {
    booking: {
      approved: 'Approved',
      canceled: 'Canceled',
      new: 'New',
    },
    points: {
      all: 'All',
      in_process: 'In process',
      new: 'New',
      purchase: 'Bought',
      refuse: 'Refused',
    }
  },

  ticketTypes: {
    financial_issues: 'Financial issues',
    technical_problem: 'Technical problem',
    booking_questions: 'Booking questions',
    digital_key_issue: 'Digital key issue',
    internet_tv_phone: 'Internet & TV',
    other: 'Other',
  }

};
