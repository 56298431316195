import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import LocalStorageService from '@/core/services/local-storage.service';
import notify from '@/core/services/notify.service';
import loader from '@/core/services/loader.service';
import i18nService from '@/core/services/i18n.service';
import router from '@/router';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.interceptors.response.use(
      (response) => {
        loader.off();
        return response;
      },
      (err) => {
        loader.off();
        const { _, config } = err;
        if(
          config?.url === 'api/auth/login' ||
          config?.url === 'api/auth/forgot_password' ||
          config?.url.includes('api/auth/reset_password') ||
          config?.url.includes('api/auth/activate') ||
          config?.url.includes('/api/qr/booking') ||
          config?.url.includes('/api/qr/book/change')
        ) {
          return Promise.reject(err);
        }

        if (err?.response?.status === 401) {
          LocalStorageService.destroyUser();
          if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'});
          }
        } else if (err?.response?.status === 404) {
          router.replace({name: '404', replace: true});
        } else if (err?.response?.status === 409) {} else {
          notify.error(err?.response?.data?.message);
        }
        return Promise.reject(err);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common = {
      'user-language': i18nService.getActiveLanguage() || '',
      'access-token': LocalStorageService.getAccessToken() || '',
      'refresh-token': LocalStorageService.getRefreshToken() || '',
      'Accept': 'application/json'
    };
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param config
   * @param withNotify
   * @param withLoader
   * @returns {*}
   */
  get(resource, config = null, withLoader = true) {
    if (withLoader) loader.on();
    return Vue.axios.get(resource, config).then((res) => {
      return res;
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param withNotify
   * @param withLoader
   * @returns {*}
   */
  post(resource, params = null, withNotify = false, withLoader = true) {
    if (withLoader) loader.on();
    return Vue.axios.post(resource, params).then((res) => {
      if (withNotify) {
        // res.data && res.data.message ? notify.success(res.data.message) : notify.success();
        notify.success(res?.data?.message);
      }
      return res;
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param withNotify
   * @param withLoader
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params = null, withNotify = false, withLoader = true) {
    if (withLoader) loader.on();
    return Vue.axios.put(resource, params).then((res) => {
      if (withNotify) {
        // res.data && res.data.message ? notify.success(res.data.message) : notify.success();
        notify.success(res?.data?.message);
      }
      return res;
    });
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @param withNotify
   * @param withLoader
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params = null, withNotify = false, withLoader = true) {
    if (withLoader) loader.on();
    return Vue.axios.patch(resource, params).then((res) => {
      if (withNotify) {
        // res.data && res.data.message ? notify.success(res.data.message) : notify.success();
        notify.success(res?.data?.message);
      }
      return res;
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param config
   * @param withNotify
   * @param withLoader
   * @returns {*}
   */
  delete(resource, config = null, withNotify = false, withLoader = true) {
    if (withLoader) loader.on();
    return Vue.axios.delete(resource, config).then((res) => {
      if (withNotify) {
        // res.data && res.data.message ? notify.success(res.data.message) : notify.success();
        notify.success(res?.data?.message);
      }
      return res;
    });
  }
};

export default ApiService;
